import '../css/libraries.css';
import '../scss/style.scss';
import { Map } from './map';
import Parallax from 'parallax-js';
require('./plugins.js');

$(function() {



    // Global variables
    var $win = $(window);

    /*==========   Parallax effect   ==========*/
    var imgFollow = document.getElementById('imgFollow');
    if (imgFollow) {
        var parallaxInstance = new Parallax(imgFollow);
    }

    /*==========   Mobile Menu   ==========*/
    var $navToggler = $('.navbar-toggler');
    $navToggler.on('click', function() {
        $(this).toggleClass('actived');
    })
    $navToggler.on('click', function() {
        $('.navbar-collapse').toggleClass('menu-opened');
    })

    // Toggle dropdown Menu in Mobile
    $('.dropdown-menu [data-toggle=dropdown]').on('click', function(e) {
        e.stopPropagation();
        e.preventDefault();
        $(this).parent().siblings().removeClass("opened");
        $(this).parent().toggleClass("opened");
    });
    $('.dropdown-submenu [data-toggle=dropdown]').on('click', function(e) {
        $(this).next().toggleClass("show");
        $(this).parent().siblings().find('.dropdown-menu').removeClass('show');
    });

    // Google Map (depend on map.js)
    var contactLocations = [
        ['Bureau Mont-Saint-Guibert', 50.6333236, 4.6063198, '<div class="mapcontent">' +
            '<h4>Bureau de Mont-Saint-Guibert</h4>' +
            '</div>'
        ],
        ['Bureau Valenciennes', 50.3620422, 3.518574, '<div class="mapcontent">' +
            '<h4>Bureau de Valenciennes</h4>' +
            '</div>'
        ]
    ];
    var urbanismeLocations = [
        [
            "Les jardins de roselle",
            50.64028885368718,
            3.7886568714059945,
            "<div class='mapcontent'>" +
            "<h4>Les jardins de roselle</h4>" +
            "<p>Logements individuels et collectifs<br>Permis d\'Urbanisation (5,2Ha)</p>" +
            "<a href='jardins-de-roselle.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Les cinq sapins",
            50.704784004888936,
            4.627687346011362,
            "<div class='mapcontent'>" +
            "<h4>Les cinq sapins</h4>" +
            "<p>Logements individuels et collectifs<br>Permis Groupé (2,8Ha)</p>" +
            "<a href='cinq-sapins.html'>Voir le projet  <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ]
    ];
    var logementsCollectifsLocations = [
        [
            "Les jardins de roselle 75",
            50.64028885368718,
            3.7886568714059945,
            "<div class='mapcontent'>" +
            "<h4>Les jardins de roselle 75</h4>" +
            "<p>Immeuble de logements avec surface commerciale</p>" +
            "<a href='jardins-de-roselle-75.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidence 'Guimard'",
            50.61675338166424,
            4.32448970729351,
            "<div class='mapcontent'>" +
            "<h4>Résidence 'Guimard'</h4>" +
            "<p>Immeuble de logements avec surface commerciale</p>" +
            "<a href='residence-guimard.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidences 'Jefferson' et 'kahn'",
            50.61620440188714,
            4.324207783285846,
            "<div class='mapcontent'>" +
            "<h4>Résidences 'Jefferson' et 'kahn'</h4>" +
            "<p>Immeuble de logements</p>" +
            "<a href='residences-jefferson-et-kahn.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidence 'Christ du quewet'",
            50.64108223118853,
            4.616377136531834,
            "<div class='mapcontent'>" +
            "<h4>Résidence 'Christ du quewet'</h4>" +
            "<p>Immeuble de logements</p>" +
            "<a href='residence-christ-du-quewet.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidence 'Cérès'",
            50.63823127996955,
            4.613698950539427,
            "<div class='mapcontent'>" +
            "<h4>Résidence 'Cérès'</h4>" +
            "<p>Immeuble de logements avec surface commerciale et bureaux</p>" +
            "<a href='residence-ceres.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidence '24 APAS'",
            50.59471939244932,
            4.323830813687481,
            "<div class='mapcontent'>" +
            "<h4>Résidence '24 APAS'</h4>" +
            "<p>Immeuble de logements avec surface commerciale</p>" +
            "<a href='residence-24-apas.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidence 'Frédéric'",
            50.602250044492216,
            4.3362825304860575,
            "<div class='mapcontent'>" +
            "<h4>Résidence 'Frédéric'</h4>" +
            "<p>Immeuble de logements avec surface commerciale</p>" +
            "<a href='residence-frederic.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidences 'Lannoy' et 'Les prés de St-Jean'",
            50.63904134150074,
            4.614166688716903,
            "<div class='mapcontent'>" +
            "<h4>Résidences 'Lannoy' et 'Les prés de St-Jean'</h4>" +
            "<p>Immeuble de logements et Services (Crèche, Cabinet médical et Pharmacie)</p>" +
            "<a href='residence-lannoy.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Les cinq sapins",
            50.70521418411336,
            4.629063643405794,
            "<div class='mapcontent'>" +
            "<h4>Les cinq sapins</h4>" +
            "<p>Immeuble de logements</p>" +
            "<a href='cinq-sapins-apparts.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidence Arquennes",
            50.56650395134856,
            4.282570013804396,
            "<div class='mapcontent'>" +
            "<h4>Résidence Arquennes</h4>" +
            "<p>Immeuble de logements et services</p>" +
            "<a href='residence-arquennes.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidence 'Le Colonel'",
            50.85382635630216,
            4.413719952514613,
            "<div class='mapcontent'>" +
            "<h4>Résidence 'Le Colonel'</h4>" +
            "<p>Immeuble de logements avec surface commerciale</p>" +
            "<a href='residence-le-colonel.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidences 'Redwood' et 'Alder'",
            50.497087995491405,
            3.9830899195932363,
            "<div class='mapcontent'>" +
            "<h4>Résidences 'Redwood' et 'Alder'</h4>" +
            "<p>Immeuble de logements</p>" +
            "<a href='residences-redwood-et-alder.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidence Grand Angle",
            50.46672782171859,
            3.811539813217137,
            "<div class='mapcontent'>" +
            "<h4>Résidence Grand Angle</h4>" +
            "<p>Immeuble de logements avec surfaces commerciales</p>" +
            "<a href='residence-grand-angle.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidence 'Athena'",
            50.592377005854225,
            4.337137600283214,
            "<div class='mapcontent'>" +
            "<h4>Résidence 'Athena'</h4>" +
            "<p>Reconversion d'une Maison de Repos en immeubles de logements</p>" +
            "<a href='residence-athena.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidence 'Hibiscus'",
            50.6391305,
            3.7888692,
            "<div class='mapcontent'>" +
            "<h4>Résidence 'Hibiscus'</h4>" +
            "<p>Construction d'un immeuble de logements</p>" +
            "<a href='residence-hibiscus.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidences 'Les villas'",
            50.3488357,
            4.8572257,
            "<div class='mapcontent'>" +
            "<h4>Résidence 'Les villas'</h4>" +
            "<p>Construction de 2 immeubles de logements</p>" +
            "<a href='residences-les-villas.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidence 'Vert Coucou'",
            50.635558, 
            4.358810,
            "<div class='mapcontent'>" +
            "<h4>Résidence 'Vert Coucou'</h4>" +
            "<p>Construction d'un immeuble à appartements & 2 maisons</p>" +
            "<a href='residence-vert-coucou.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidences 'Côté Parc'",
            50.591667, 
            4.328056,
            "<div class='mapcontent'>" +
            "<h4>Résidences 'Côté Parc'</h4>" +
            "<p>Construction de 4 immeubles à appartements</p>" +
            "<a href='residences-cote-parc.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidences 'Longue Haie'",
            50.6793916,
            4.3706339,
            "<div class='mapcontent'>" +
            "<h4>Résidences 'Longue Haie'</h4>" +
            "<p>Construction d'un immeuble à appartements</p>" +
            "<a href='residences-longue-haie.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Étude de notaire",
            50.7163424,
            4.6203802,
            "<div class='mapcontent'>" +
            "<h4>Étude de notaire</h4>" +
            "<p>Construction d'une étude de notaire & 3 appartements</p>" +
            "<a href='etude-de-notaire.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidence 'Les Sorbiers'",
            50.7199789,
            4.6243448,
            "<div class='mapcontent'>" +
            "<h4>Résidence 'Les Sorbiers'</h4>" +
            "<p>Construction d'un immeuble de logements</p>" +
            "<a href='residence-les-sorbiers.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidence 'Evere'",
            50.854589,
            4.4147625,
            "<div class='mapcontent'>" +
            "<h4>Résidence 'Evere'</h4>" +
            "<p>Transformation d'un immeuble de bureaux en logements</p>" +
            "<a href='residence-evere.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidence 'Link'",
            50.485382, 
            4.555550,
            "<div class='mapcontent'>" +
            "<h4>Résidence 'Link'</h4>" +
            "<p>Construction d'un immeuble de logements</p>" +
            "<a href='residence-link.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidence 'Seamar'",
            50.5995295,
            4.3269258,
            "<div class='mapcontent'>" +
            "<h4>Résidence 'Seamar'</h4>" +
            "<p>Construction d'un immeuble de logements au dessus d'une surface commerciale existante</p>" +
            "<a href='residence-seamar.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidence 'Béclines'",
            50.639167, 
            4.615278,
            "<div class='mapcontent'>" +
            "<h4>Résidence 'Béclines'</h4>" +
            "<p>Construction d'un immeuble de logements & cabinet médical</p>" +
            "<a href='residence-beclines.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],

        

    ];

    var logementsIndividuelsLocations = [
        [
            "Les jardins de roselle",
            50.64133837203485,
            3.788319340500564,
            "<div class='mapcontent'>" +
            "<h4>Les jardins de roselle</h4>" +
            "<p>Construction de maisons unifamiliales </p>" +
            "<a href='jardins-de-roselle-75.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "LLN-malaise",
            50.657192696134686,
            4.595607054792315,
            "<div class='mapcontent'>" +
            "<h4>LLN-malaise</h4>" +
            "<p>Construction d’une maison unifamiliale</p>" +
            "<a href='lln-malaise.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Les cinq sapins",
            50.704854554976826,
            4.627702182069752,
            "<div class='mapcontent'>" +
            "<h4>Les cinq sapins</h4>" +
            "<p>Construction de maisons uni et bi-familiales</p>" +
            "<a href='cinq-sapins-maison.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Mont-Saint-Guibert Ornoy",
            50.63088933970226,
            4.604654047757792,
            "<div class='mapcontent'>" +
            "<h4>Mont-Saint-Guibert Ornoy</h4>" +
            "<p>Construction d'une maison unifamiliale</p>" +
            "<a href='msg-ornoy.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Nivelles Campagne du Petit Baulers",
            50.6173333,
            4.3205586,
            "<div class='mapcontent'>" +
            "<h4>Nivelles Campagne du Petit Baulers</h4>" +
            "<p>Construction de maisons unifamiliales</p>" +
            "<a href='petit-baulers.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Mont-Saint-Guibert Petit Baty",
            50.6441082,
            4.6402591,
            "<div class='mapcontent'>" +
            "<h4>Mont-Saint-Guibert Petit Baty</h4>" +
            "<p>Construction de maisons unifamiliales</p>" +
            "<a href='petit-baty.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Nivelles îlot Maillebotte",
            50.6000056,
            4.344675,
            "<div class='mapcontent'>" +
            "<h4>Nivelles îlot Maillebotte</h4>" +
            "<p>Construction de maisons unifamiliales</p>" +
            "<a href='ilot-maillebotte.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Court-St-Etienne Suzeril",
            50.63675,
            4.5603641,
            "<div class='mapcontent'>" +
            "<h4>Court-St-Etienne Suzeril</h4>" +
            "<p>Construction de maisons unifamiliales</p>" +
            "<a href='suzeril.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Waterloo Léopold",
            50.7225833,
            4.3948364,
            "<div class='mapcontent'>" +
            "<h4>Waterloo Léopold</h4>" +
            "<p>Construction d'une maison unifamiliale</p>" +
            "<a href='waterloo-leopold.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],

    ];

    var senioriesLocations = [
        [
            "Résidence 'Au bon vieux temps'",
            50.63861738989357,
            4.6154187155840924,
            "<div class='mapcontent'>" +
            "<h4>Résidence 'Au bon vieux temps'</h4>" +
            "<p>Maison de repos et de soins et résidence services</p>" +
            "<a href='au-bon-vieux-temps.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidence 'L'air du temps'",
            50.609836849308174,
            5.629466003431933,
            "<div class='mapcontent'>" +
            "<h4>Résidence 'L'air du temps'</h4>" +
            "<p>Maison de repos et de soins et résidence services</p>" +
            "<a href='air-du-temps.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Résidence 'Bois de la Pierre'",
            50.6966538,
            4.6104677,
            "<div class='mapcontent'>" +
            "<h4>Résidence 'Bois de la Pierre'</h4>" +
            "<p>Extension d'une maison de repos et de soins</p>" +
            "<a href='bois-de-la-pierre.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
    ];

    var marchesPublicsLocations = [
        [
            "Station services 'TEXACO'",
            50.719086010729335,
            4.584240820370586,
            "<div class='mapcontent'>" +
            "<h4>Station services 'TEXACO'</h4>" +
            "<p>Construction d'une station-services</p>" +
            "<a href='station-services-texaco.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "Ciney stade Lambert",
            50.2887778,
            5.0943364,
            "<div class='mapcontent'>" +
            "<h4>Ciney stade Lambert</h4>" +
            "<p>Construction de maisons unifamiliales</p>" +
            "<a href='stade-lambert.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        [
            "MONS Domaine d'Épinlieu",
            50.456,
            3.9977253,
            "<div class='mapcontent'>" +
            "<h4>Mons Domaine d'Épinlieu</h4>" +
            "<p>Construction de logements publics & privés</p>" +
            "<a href='domaine-epinlieu.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
        
    ];

    var diversLocations = [
        [
            "Les jardins de roselle",
            50.638924,
            3.788203,
            "<div class='mapcontent'>" +
            "<h4>Les jardins de roselle</h4>" +
            "<p>Maisons unifamiliales - Mission complète</p>" +
            "<a href='jardins-de-roselle-75.html'>Voir le projet <i class='fa fa-arrow-right'></i></a>" +
            "</div>"
        ],
    ];


    let mapContact = document.getElementById('mapContact');
    let mapUrbanisme = document.getElementById('mapUrbanisme');
    let mapLogementsCollectifs = document.getElementById('mapLogementsCollectifs');
    let mapLogementsIndividuels = document.getElementById('mapLogementsIndividuels');
    let mapSeniories = document.getElementById('mapSeniories');
    let mapMarchesPublics = document.getElementById('mapMarchesPublics');
    let mapDivers = document.getElementById('mapDivers');

    if (mapUrbanisme) {
        Map.loadGoogleMapsApi().then(function(googleMaps) {
            let map = Map.createMap(googleMaps, mapUrbanisme, 10);
            Map.createMarker(map, urbanismeLocations);
        });
    } else if (mapLogementsCollectifs) {
        Map.loadGoogleMapsApi().then(function(googleMaps) {
            let map = Map.createMap(googleMaps, mapLogementsCollectifs, 10);
            Map.createMarker(map, logementsCollectifsLocations);
        });
    } else if (mapLogementsIndividuels) {
        Map.loadGoogleMapsApi().then(function(googleMaps) {
            let map = Map.createMap(googleMaps, mapLogementsIndividuels, 10);
            Map.createMarker(map, logementsIndividuelsLocations);
        });
    } else if (mapSeniories) {
        Map.loadGoogleMapsApi().then(function(googleMaps) {
            let map = Map.createMap(googleMaps, mapSeniories, 10);
            Map.createMarker(map, senioriesLocations);
        });
    } else if (mapMarchesPublics) {
        Map.loadGoogleMapsApi().then(function(googleMaps) {
            let map = Map.createMap(googleMaps, mapMarchesPublics, 10);
            Map.createMarker(map, marchesPublicsLocations);
        });
    } else if (mapDivers) {
        Map.loadGoogleMapsApi().then(function(googleMaps) {
            let map = Map.createMap(googleMaps, mapDivers, 10);
            Map.createMarker(map, diversLocations);
        });
    } else if (mapContact) {
        Map.loadGoogleMapsApi().then(function(googleMaps) {
            let map = Map.createMap(googleMaps, mapContact, 5);
            Map.createMarker(map, contactLocations);
        });
    }


    // linkedin Embedded Content
    // let linkedinEmbeddedContent = document.getElementById('linkedinEmbeddedContent');
    // if (linkedinEmbeddedContent) {

    //     $.get("https://jsonblob.com/api/0022ada4-c44d-11ea-ae99-a1d1244c91d9",
    //         function(data, textStatus, jqXHR) {
    //             var i;
    //             // var posts = ["6683657552972050432", "6601027637471260672"];
    //             var curi = "";
    //             var posts = data.posts
    //             for (i = 0; i < posts.length; i++) {
    //                 curi = "https://www.linkedin.com/embed/feed/update/urn:li:share:" + posts[i];
    //                 var iframe = document.createElement('iframe');
    //                 iframe.src = encodeURI(curi);
    //                 iframe.style.width = "100%";
    //                 // iframe.style.height = "400px";
    //                 iframe.style.frameborder = "0";
    //                 iframe.style.allowfullscreen = "";
    //                 linkedinEmbeddedContent.appendChild(iframe);
    //                 // console.log('iframe.contentWindow =', iframe.contentWindow);
    //             }
    //         });

    // }



    /*==========   Sticky Navbar   ==========*/
    // $win.on('scroll', function() {
    //     if ($win.width() >= 992) {
    //         var $navbar = $('.navbar');
    //         if ($win.scrollTop() > 20) {
    //             $navbar.addClass('fixed-navbar');
    //         } else {
    //             $navbar.removeClass('fixed-navbar');
    //         }
    //     }
    // });



    /*==========   Scroll Top Button   ==========*/
    var $scrollTopBtn = $('#scrollTopBtn');
    var $scrollDownBtn = $('#scrollDownBtn');
    // Show Scroll Top Button
    $win.on('scroll', function() {
        if ($(this).scrollTop() > 700) {
            $scrollTopBtn.addClass('actived');
        } else {
            $scrollTopBtn.removeClass('actived');
        }
        if ($(this).scrollTop() > 30) {
            $scrollDownBtn.addClass('disabled');
        } else {
            $scrollDownBtn.removeClass('disabled');
        }
    });
    // Animate Body after Clicking on Scroll Top Button
    $scrollTopBtn.on('click', function() {
        $('html, body').animate({
            scrollTop: 0
        }, 500);
    });

    /*==========   Smooth scrolling   ==========*/
    $('a.scroll-trigger[href*="#"]:not([href="#"])').on('click', function() {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: (target.offset().top - 90)
                }, 600);
                return false;
            }
        }
    });

    /*==========   Set Background-img to section   ==========*/
    $('.bg-img').each(function() {
        var imgSrc = $(this).children('img').attr('src');
        $(this).parent().css({
            'background-image': 'url(' + imgSrc + ')',
            'background-size': 'cover',
            'background-position': 'center',
        });
        $(this).parent().addClass('bg-img');
        $(this).remove();
    });


    /*==========   Add active class to accordions   ==========*/
    $('.accordion__item-header').on('click', function() {
        $(this).toggleClass('opened')
        $(this).parent().parent().siblings().find('.accordion__item-header').removeClass('opened');
        $(this).parent().siblings().find('.accordion__item-header').removeClass('opened')
    })
    $('.accordion__item-title').on('click', function(e) {
        e.preventDefault()
    });


    /*============ CLose Contact Panel  ===========*/
    $('.close-contact-panel').on('click', function() {
        $(this).closest('.contact-panel').fadeOut();
    });


    /*==========   Owl Carousel  ==========*/
    // $('.carousel').owlCarousel({
    //     loop: true,
    //     margin: 0,
    //     autoplay: $(this).data('autoplay'),
    //     nav: $(this).data('nav'),
    //     dots: $(this).data('dots'),
    //     dotsSpeed: $(this).data('speed'),
    //     autoplayHoverPause: true,
    //     singleItem: true,
    //     slideTransition: 'linear',
    //     animateOut: 'fadeOut',
    //     animateIn: 'fadeIn',
    //     autoplayTimeout: 1000,
    //     responsive: {
    //         0: {
    //             items: 1
    //         },
    //         600: {
    //             items: 1
    //         },
    //         1000: {
    //             items: 1
    //         }
    //     }
    // });


    $('.client-carousel').owlCarousel({
        nav: $(this).data('nav'),
        dots: $(this).data('dots'),
        // loop: $(this).data('loop'),
        margin: $(this).data('space'),
        center: $(this).data('center'),
        // dotsSpeed: $(this).data('speed'),
        // rtl: true,
        loop: true,
        autoplay: true,
        slideTransition: 'linear',
        autoplayTimeout: 3000,
        autoplaySpeed: 3000,
        autoplayHoverPause: false,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        freeDrag: false,
        rewindNav: false,
        rewindSpeed: 0,


        responsive: {
            0: {
                items: 3,
            },
            400: {
                items: 4,
            },
            700: {
                items: 6,
            },
            1000: {
                items: 9,
            }
        }
    });

    // Owl Carousel With Thumbnails
    $('.thumbs-carousel').owlCarousel({
        thumbs: true,
        thumbsPrerendered: true,
        loop: true,
        margin: 0,
        autoplay: $(this).data('autoplay'),
        nav: $(this).data('nav'),
        dots: $(this).data('dots'),
        dotsSpeed: $(this).data('speed'),
        transitionStyle: $(this).data('transition'),
        animateOut: $(this).data('animate-out'),
        animateIn: $(this).data('animate-in'),
        autoplayTimeout: 15000,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    });

    /*==========  Popup Video  ==========*/
    // $('.popup-video').magnificPopup({
    //     mainClass: 'mfp-fade',
    //     preloader: false,
    //     fixedContentPos: false,
    //     removalDelay: 0,
    //     type: 'iframe',
    //     iframe: {
    //         markup: '<div class="mfp-iframe-scaler">' +
    //             '<div class="mfp-close"></div>' +
    //             '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
    //             '</div>',
    //         patterns: {
    //             youtube: {
    //                 index: 'youtube.com/',
    //                 id: 'v=',
    //                 src: '//www.youtube.com/embed/%id%?autoplay=1'
    //             }
    //         },
    //         srcAction: 'iframe_src',
    //     }
    // });
    // $(".portfolio-item a").on('click', function(e) {
    //     alert('ss')
    //     e.preventDefault();
    // });

    $('.popup-gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Charge l\'image #%curr%...',
        mainClass: 'mfp-with-zoom mfp-img-mobile',
        closeOnContentClick: true,
        closeBtnInside: true,

        fixedContentPos: true,
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1]
        },
        image: {
            verticalFit: true,
            titleSrc: function(item) {
                return item.el.find('img').attr('alt') + '<small>&copy; BEL</small>';
            },
            tError: '<a href="%url%">L\'image #%curr%</a> ne peut pas être chargée.'
        },
        zoom: {
            enabled: true,
            duration: 300, // don't foget to change the duration also in CSS
            opener: function(element) {
                return element.find('img');
            }
        }
    });

    $('.popup-gallery-25').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Charge l\'image #%curr%...',
        mainClass: 'mfp-with-zoom mfp-img-mobile',
        closeOnContentClick: true,
        closeBtnInside: true,

        fixedContentPos: true,
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1]
        },
        image: {
            verticalFit: true,
            titleSrc: function(item) {
                return '<small>&copy; <a href="https://www.linkedin.com/in/mathilde-schockaert-81b872138/" target="_blank">Mathilde Schockaert</a>｜<a href="https://www.instagram.com/septembre.atelier/" target="_blank">Septembre.Atelier</a> </small>';
            },
            tError: '<a href="%url%">L\'image #%curr%</a> ne peut pas être chargée.'
        },
        zoom: {
            enabled: true,
            duration: 300, // don't foget to change the duration also in CSS
            opener: function(element) {
                return element.find('img');
            }
        }
    });


    /*==========   counterUp  ==========*/
    $('.counter').counterUp({
        delay: 10,
        time: 1000
    });


    /*==========  image zoomsl Plugin  ==========*/
    // [Zoom Effect on Hovering] Find it in shop-single-product.html
    $(".zoomin").imagezoomsl();


    // Always shows the current year in footer
    document.getElementById("year").innerHTML = new Date().getFullYear();



});